import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';

import { getAuth, isAuthenticated } from '../state/selectors/auth';
import Loader from '../components/common/loader';
import PublicLayout from '../layout/public-layout';
import { navigateTo } from '../state/actions/routing';
import UserAuthContainer from '../containers/auth/user-auth';
import { getQueryParam } from '../utils/common';

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 30px;
`;

export default function Signup({ location }: RouteComponentProps<{}>) {
  const { loading } = useSelector(getAuth);
  const loggedIn = useSelector(isAuthenticated);
  const dispatch = useDispatch();
  const { pathname } = location || {};
  let initialEmail = getQueryParam('email', location);
  if (initialEmail?.includes(' ')) initialEmail = initialEmail.replace(/\s/g, '+');

  useEffect(() => {
    let redirectPath;
    if (loggedIn && pathname !== '/myhsf') {
      redirectPath = '/myhsf';
    }

    if (redirectPath) {
      dispatch(navigateTo(redirectPath));
    }
  }, [loggedIn, pathname, dispatch]);

  if (loading) {
    return <Loader></Loader>;
  }

  return (
    <PublicLayout>
      <Container>
        <UserAuthContainer pathname={pathname || ''} initialEmail={initialEmail} />
      </Container>
    </PublicLayout>
  );
}
